import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Container,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import { Print as PrintIcon, Email as EmailIcon } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { useParams, useNavigate } from 'react-router-dom';

import { useUserAuth } from '../../context/UserAuthContext';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import EmailDialog from '../../components/SendEmailDialog/SendEmailDialog';
import CouponEmailDialog from '../../components/SendCouponEmailDialog/SendCouponEmailDialog';
import CouponDialog from '../../components/CouponDialog/CouponDialog';
import ResendCouponDialog from '../../components/ResendCouponDialog/ResendCouponDialog';
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';

const PDFViewer = () => {
  const navigate = useNavigate();

  const {
    user: firebaseUser,
    loading: authenticating,
    selectedStore,
  } = useUserAuth();
  const { exportBatchId, oloInvoiceNumber } = useParams();
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
    selectedStore,
  });
  const [couponInfo, setCouponInfo] = useState();
  const [invoiceHtml, setInvoiceHTML] = useState();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isCouponEmailDialogOpen, setIsCouponEmailDialogOpen] = useState(false);
  const [isCouponDialogOpen, setIsCouponDialogOpen] = useState(false);
  const [isResendCouponDialogOpen, setIsResendCouponDialogOpen] =
    useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [couponsRedeemed, setCouponsRedeemed] = useState(false);
  const [couponsAssigned, setCouponsAssigned] = useState(false);
  const [couponsSent, setCouponsSent] = useState(false);

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetUserInfo();
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (orderDetails?.couponCount > 0) {
      setCouponsAssigned(true);
      setCouponInfo(orderDetails.couponCodes);
    }
    if (orderDetails?.couponsSent > 0) {
      setCouponsSent(true);
    }
  }, [orderDetails]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
        setLoading(false);
      } else {
        setFormData({
          ...response.data.userInfo,
          defaultStore: selectedStore,
        });
        handleGetOrderInfo();
      }
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const fetchInvoiceHTML = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/olo/invoice/batch=${exportBatchId}/order=${oloInvoiceNumber}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setInvoiceHTML(response.data);
      setLoading(false);
    } catch (err) {
      setErrorMessage('Failed to load PDF');
      setLoading(false);
    }
  };

  const handleGetOrderInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/olo/order=${oloInvoiceNumber}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setOrderDetails(response.data);
      fetchInvoiceHTML();
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleSendEmail = async (to, replyTo, subject, body) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Make the API call with axios
      await axios.post(
        `/hd-api/olo/send-email`,
        {
          to,
          replyTo,
          subject,
          body,
          orderId: oloInvoiceNumber,
          exportBatchId,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleSendCouponEmail = async (to, replyTo, subject, body) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Make the API call with axios
      await axios.post(
        `/hd-api/olo/coupons/send-email`,
        {
          to,
          replyTo,
          subject,
          body,
          orderId: oloInvoiceNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      handleGetOrderInfo();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleOpenEmailDialog = () => {
    setIsEmailDialogOpen(true);
  };

  const handleCloseEmailDialog = () => {
    setIsEmailDialogOpen(false);
  };

  const handleOpenCouponDialog = () => {
    if (!couponsAssigned) {
      setIsCouponDialogOpen(true);
    } else {
      setIsCouponEmailDialogOpen(true);
    }
  };

  const handleOpenResendCouponDialog = () => {
    setIsResendCouponDialogOpen(true);
  };

  const handleCloseCouponDialog = () => {
    setIsCouponDialogOpen(false);
  };

  const handleCloseResendCouponDialog = () => {
    setIsResendCouponDialogOpen(false);
  };

  const handlePrint = async () => {
    // Logic to handle printing invoices for the selected invoice
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Request to generate and fetch the merged PDF
      const response = await axios.post(
        `/hd-api/olo/invoice/print`, // Ensure this endpoint triggers the PDF creation and merging
        { invoiceNumber: oloInvoiceNumber },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          responseType: 'blob', // Important to handle the binary data correctly
        }
      );

      // Create a blob URL for the PDF
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');
      setLoading(false);
    } catch (error) {
      console.error('Failed to print order:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateCoupons = async (couponData) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.post(
        `/hd-api/olo/coupons/generate`,
        {
          couponData,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      setCouponInfo(couponData);
      setIsCouponDialogOpen(false);
      setIsCouponEmailDialogOpen(true);
    } catch (e) {
      console.log(e);
      setIsCouponDialogOpen(false);
      setErrorMessage(
        `There was an error generating offer codes. We've notified IT support and will address this as quickly as possible.`
      );
    }
  };
  let content;
  if (authenticating) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', marginTop: 8 }}>
        <Typography
          style={{ fontFamily: 'Lato', fontSize: 28, marginBottom: 16 }}
        >
          OLO Invoice # {oloInvoiceNumber}
        </Typography>
        <Grid container justifyContent='space-evenly'>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Tooltip title='Print'>
              <IconButton onClick={handlePrint}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Email'>
              <IconButton onClick={handleOpenEmailDialog}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid xs={4}>
            {couponsSent ? (
              <Button
                size='small'
                variant='contained'
                style={{ fontFamily: 'Lato' }}
                onClick={handleOpenResendCouponDialog}
              >
                Resend Codes
              </Button>
            ) : (
              <Button
                size='small'
                variant='contained'
                style={{ fontFamily: 'Lato' }}
                onClick={handleOpenCouponDialog}
              >
                Make It Right
              </Button>
            )}
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            marginBottom: '16px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: 'white',
            padding: 32,
            transform: 'scale(0.75)', // Adjust the scale to zoom out
            transformOrigin: 'top',
            overflow: 'auto',
            margin: 'auto',
          }}
          dangerouslySetInnerHTML={{ __html: invoiceHtml }}
        ></div>
      </div>
    );
  }
  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='xl'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
      {isEmailDialogOpen && (
        <EmailDialog
          storeId={orderDetails?.storeId}
          storeName={orderDetails?.storeName}
          invoiceNumber={oloInvoiceNumber}
          open={isEmailDialogOpen}
          onClose={handleCloseEmailDialog}
          emailAddress={orderDetails?.emailAddress}
          confirmSend={handleSendEmail}
        />
      )}
      {isCouponEmailDialogOpen && (
        <CouponEmailDialog
          couponInfo={couponInfo}
          storeId={orderDetails?.storeId}
          storeName={orderDetails?.storeName}
          name={orderDetails?.name}
          invoiceNumber={oloInvoiceNumber}
          open={isCouponEmailDialogOpen}
          onClose={() => setIsCouponEmailDialogOpen(false)}
          // emailAddress={orderDetails?.emailAddress}
          emailAddress={firebaseUser.email}
          confirmSend={handleSendCouponEmail}
        />
      )}
      {errorMessage && (
        <ErrorDialog onClose={() => setErrorMessage()} message={errorMessage} />
      )}
      {isResendCouponDialogOpen && (
        <ResendCouponDialog
          couponInfo={orderDetails?.couponCodes}
          storeId={orderDetails?.storeId}
          storeName={orderDetails?.storeName}
          name={orderDetails?.name}
          invoiceNumber={oloInvoiceNumber}
          open={isResendCouponDialogOpen}
          onClose={handleCloseResendCouponDialog}
          // emailAddress={orderDetails?.emailAddress}
          emailAddress={firebaseUser.email}
          confirmSend={handleSendCouponEmail}
        />
      )}
      {isCouponDialogOpen && (
        <CouponDialog
          handleGenerateCoupons={handleGenerateCoupons}
          invoiceNumber={oloInvoiceNumber}
          open={isCouponDialogOpen}
          onClose={handleCloseCouponDialog}
        />
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default PDFViewer;
