import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';

const ErrorDialog = ({ onClose, message }) => {
  return (
    <Dialog open={message} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography variant='body1' color='textSecondary'>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
