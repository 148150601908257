import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import Papa from 'papaparse';

const ImportCouponsDialog = ({ loading, open, onClose, onImport }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError('');
  };

  const handleImport = () => {
    if (!file) {
      setError('Please select a CSV file.');
      return;
    }

    Papa.parse(file, {
      complete: handleParseComplete,
      header: true,
    });
  };

  const handleParseComplete = (result) => {
    const couponCodes = result.data.map(({ Code }) => ({
      Code,
    }));

    // Send parsed data to backend
    onImport(couponCodes);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>
        <Typography variant='h6' style={{ fontFamily: 'Lato' }}>
          Import Offer Codes
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{ textAlign: 'center', paddingLeft: 110, paddingTop: 12 }}
      >
        <input
          type='file'
          accept='.csv'
          onChange={handleFileChange}
          style={{ margin: '20px 0' }}
        />
        {error && <Typography color='error'>{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ fontFamily: 'Lato' }}
          onClick={onClose}
          color='error'
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{ fontFamily: 'Lato' }}
          onClick={handleImport}
          color='primary'
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCouponsDialog;
