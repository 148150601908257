import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Button,
} from '@mui/material';

function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, '');
  if (phoneNumber.startsWith('1')) {
    phoneNumber = phoneNumber.substring(1);
  }
  if (phoneNumber.length !== 10) {
    return 'Invalid phone number';
  }
  const areaCode = phoneNumber.substring(0, 3);
  const firstThree = phoneNumber.substring(3, 6);
  const lastFour = phoneNumber.substring(6);
  return `(${areaCode}) ${firstThree}-${lastFour}`;
}

const OrderTable = ({
  handleViewInvoice,
  coupons,
  order,
  orderBy,
  totalCount,
  handleRequestSort,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  page,
  columnMap,
  stableSort,
  getComparator,
}) => {
  const handleSortRequest = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const sortedRows = stableSort(coupons, getComparator(order, orderBy));

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {[
              'oloRef',
              'storeName',
              'menuItem',
              'reason',
              'lastSent',
              'redeemed',
              'redeemedOrder',
              'name',
              'emailAddress',
              'phoneNumber',
            ].map((column) => (
              <TableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={handleSortRequest(column)}
                >
                  {columnMap[column]}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((orderData, index) => (
            <React.Fragment key={index}>
              <TableRow
                sx={{
                  backgroundColor: 'inherit',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                <TableCell align='left'>
                  <Button
                    onClick={() =>
                      handleViewInvoice(
                        orderData.exportBatchNumber,
                        orderData.oloRef
                      )
                    }
                  >
                    {orderData.oloRef}
                  </Button>
                </TableCell>
                <TableCell>{orderData.storeName}</TableCell>
                <TableCell>{orderData.menuItem}</TableCell>
                <TableCell>{orderData.reason}</TableCell>
                <TableCell>{orderData.lastSent}</TableCell>
                <TableCell>{orderData.redeemed}</TableCell>
                <TableCell align='left'>
                  <Button
                    disabled={!orderData.redeemedOrder}
                    onClick={() =>
                      handleViewInvoice(
                        orderData.exportBatchNumber,
                        orderData.redeemedOrder
                      )
                    }
                  >
                    {orderData.redeemedOrder ? orderData.redeemedOrder : '-'}
                  </Button>
                </TableCell>
                <TableCell>{orderData.name}</TableCell>
                <TableCell>{orderData.emailAddress}</TableCell>
                <TableCell>
                  {formatPhoneNumber(orderData.phoneNumber)}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default OrderTable;
